<template>
  <div class="create-occurrences">
    <information-card
      :icon="userIcon"
      :title="studentName"
    />

    <div
      v-for="(category, index) in categories"
      :key="index"
      class="categories-list"
    >
      <div class="category-title">
        {{ category.category_name }}
      </div>

      <v-list>
        <v-list-item-group
          v-model="selected[index]"
          multiple
        >
          <occurrences-criteria-list-item
            v-for="(criterium) in category.criteria"
            :key="criterium.id"
            :categories-criterium="criterium"
          />
        </v-list-item-group>
      </v-list>
    </div>

    <v-textarea
      v-model="description"
      label="Ocorrência"
      class="occurrence-description"
      rows="4"
    />

    <div class="send-button">
      <edudash-button
        :full-width="true"
        :disabled="disabled"
        @click="sendOccurrence"
      />
    </div>
  </div>
</template>

<script>

import InformationCard from '@/components/cards/Information.vue';
import OccurrencesCriteriaListItem from '@/components/lists/OccurrencesCriteria.vue';
import EdudashButton from '@/components/forms/EdudashButton.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'HealthAndIndiscipline',
  components: {
    InformationCard,
    OccurrencesCriteriaListItem,
    EdudashButton,
  },
  data() {
    return {
      userIcon: 'person',
      name: '',
      categoriesList: [],
      description: this.occurrenceDescription,
      selected: [],
      day: this.$route.params.day,
    };
  },
  computed: {
    ...mapGetters([
      'getCategories',
      'getOccurrenceSuccess',
      'getStudent',
      'getSchoolClass',
    ]),
    studentName() {
      return this.getStudent.name;
    },
    categories() {
      return this.getCategories;
    },
    occurrenceDescription() {
      if (this.getCategories[0].occurrence_description) {
        return this.getCategories[0].occurrence_description;
      }
      return '';
    },
    disabled() {
      return !this.description && this.categoriesList
        .every((element) => element.criteria.every((criterium) => !criterium.selected));
    },
  },
  watch: {
    getCategories(value) {
      this.categoriesList = value;
      this.description = value[0].occurrence_description;
    },
    getOccurrenceSuccess(value) {
      if (value) {
        this.changeOccurrenceSuccess();
        this.$router.push({
          name: 'presenceList',
          params: {
            schoolClassId: this.getSchoolClass.id,
            studentId: this.$route.params.studentId,
          },
        });
      }
    },
  },
  mounted() {
    const params = {
      school_class_id: this.getSchoolClass.id,
      student_id: this.$route.params.studentId,
      day: this.$route.params.day,
      kind: 'indiscipline_and_health',
    };
    this.findCategories(params);
  },
  methods: {
    ...mapActions([
      'findCategories',
      'createHealthAndIndisciplineOccurrence',
      'changeOccurrenceSuccess',
    ]),
    sendOccurrence() {
      const occurrence = {
        description: this.description,
        school_class_id: this.getSchoolClass.id,
        student_id: this.$route.params.studentId,
        day: this.$route.params.day,
        criteria: [],
        kind: 'indiscipline_and_health',
      };

      this.createHealthAndIndisciplineOccurrence({
        occurrence,
        healthIndiscipline: this.categoriesList,
      });
    },
  },
};
</script>

<style lang="scss">
  .create-occurrences {
    padding: 10px 16px;

    .category-title {
      @include font-open-sans-bold;
      font-size: $font-size-small;
      line-height: 16px;
      color: $text-color-primary;
      margin: 12px 0 22px 0;
    }

    .information-card {
      .information-title {
        color: $text-color-secondary;
        line-height: 16px;
      }

      .information-icon {
        color: $border-color-thick;
      }
    }

    .occurrence-description {
      @include font-monteserrat-semi-bold;
      color: $text-color-secondary;
      line-height: 16px;
      font-size: $font-size-extra-small;
      margin-top: 10px;
    }

    .v-input {
      .v-label {
        @include font-monteserrat-semi-bold;
        color: $text-color-secondary;
        line-height: 16px;
        font-size: $font-size-extra-small;
      }

      textarea {
        margin-top: 8px;
        color: $text-color-secondary;
        font-size: $font-size-extra-small;
      }
    }

    .v-text-field:not(.v-input--has-state):hover > .v-input__control > .v-input__slot:before {
      border-color: $border-color;
    }

    .v-list-item__content {
      padding: 0;
    }

    .send-button {
      width: 164px;
    }
  }
</style>
