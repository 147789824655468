<template>
  <div class="occurrences-criteria">
    <v-list-item
      :value="categoriesCriterium.occurrence_criterium.id"
      class="pa-0 criterium-line"
    >
      <template v-slot:default="{ }">
        <v-list-item-action class="ma-0">
          <v-checkbox
            v-model="categoriesCriterium.selected"
            :label="categoriesCriterium.occurrence_criterium.name"
          />
        </v-list-item-action>
      </template>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'OccurrencesCriteriaListItem',
  props: {
    categoriesCriterium: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
  .occurrences-criteria {
    margin-bottom: 8px;

    .criterium-title {
      @include font-monteserrat-semi-bold;
      color: $text-color-secondary;
      line-height: 16px;
      font-size: $font-size-extra-small;
      margin-left: 4px;
    }

    .v-list-item {
      min-height: initial;
    }

    .criterium-line {
      margin-bottom: 8px;
    }

    .theme--light.v-icon {
      color: $border-color-thick;
    }

    .v-list-item--link:before {
      background-color: initial;
      transition: initial;
    }

    .v-list-item__action {
      width: 100%;
    }

    .v-input--selection-controls.v-input {
      width: 100%;
    }
  }
</style>
