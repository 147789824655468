<template>
  <v-card
    max-width="344"
    class="mx-auto information-card"
    elevation="0"
  >
    <v-list-item class="information-list">
      <v-list-item-icon>
        <v-icon class="information-icon">
          {{ informationIcon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="information-title">
          {{ informationTitle }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: 'InformationCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      informationTitle: this.title,
      informationIcon: this.icon,
    };
  },
};
</script>
<style lang="scss">
.information-card {
  @include font-open-sans-semi-bold;

  .information-list {
    padding: 0;
  }

  .information-icon {
    color: $color-primary;
  }

  .information-title {
    color: $text-color-primary;
    font-size: $font-size-extra-small;
  }

  .v-list-item__icon:first-child {
    margin-right: 8px;
  }
}
</style>
