<template>
  <div class="presence">
    <edudash-date-picker
      v-model="date"
      :events="presenceListDays"
      class="date-picker"
    />
    <div class="presence-list">
      <div class="presence-list-title">
        {{ $t('views.presence.list.present') }}
      </div>
      <v-list
        flat
        class="presence-list-students"
      >
        <v-list-item-group>
          <presence-list-item
            v-for="(student, index) in presenceList"
            :key="index"
            :student="student"
            @studentClick="addOccurrences"
          />
        </v-list-item-group>
      </v-list>
    </div>
    <div class="send-button">
      <edudash-button
        :full-width="true"
        @click="sendPresence"
      />
    </div>
  </div>
</template>
<script>
import EdudashDatePicker from '@/components/forms/EdudashDatePicker.vue';
import EdudashButton from '@/components/forms/EdudashButton.vue';
import PresenceListItem from '@/components/presence/ListItem.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PresenceList',
  components: {
    EdudashDatePicker,
    PresenceListItem,
    EdudashButton,
  },
  data() {
    return {
      schoolClassId: this.$route.params.schoolClassId,
      date: new Date().toISOString().substr(0, 10),
      presenceList: [],
    };
  },
  computed: {
    ...mapGetters([
      'getPresenceList',
      'getPresenceListDays',
    ]),
    presenceListDays() {
      return this.getPresenceListDays;
    },
  },
  watch: {
    date() {
      const params = {
        day: this.date,
        school_class_id: this.schoolClassId,
      };
      this.findPresenceList(params);
    },
    getPresenceList(value) {
      this.presenceList = value;
    },
  },
  mounted() {
    const params = {
      day: this.date,
      school_class_id: this.schoolClassId,
    };
    this.findPresenceList(params);
    this.findPresenceListDays({ school_class_id: this.schoolClassId });
  },
  methods: {
    ...mapActions([
      'findPresenceList',
      'findPresenceListDays',
      'sendPresentStudents',
      'saveStudent',
    ]),
    sendPresence() {
      const absentStudents = this.presenceList.reduce((absents, student) => {
        const studentInfos = {
          student_presence_list_id: student.id,
          absence_status: student.attendance,
        };
        absents.push(studentInfos);

        return absents;
      }, []);
      const params = {
        day: this.date,
        school_class_id: this.schoolClassId,
        absent_students: absentStudents,
      };
      this.sendPresentStudents(params);
    },
    addOccurrences(value) {
      this.saveStudent(value);
      this.$router.push({
        name: 'healthAndIndiscipline',
        params: {
          day: this.date,
          studentId: value.student_id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.presence {
  position: relative;
  margin-bottom: 76px;
  .date-picker{
    padding: 20px 16px;
  }
  .presence-list {
    .presence-list-title {
      @include font-monteserrat-semi-bold;
      color: $text-color-secondary;
      font-size: $font-size-extra-small;
      padding: 0 12px;
    }
    .presence-list-students {
      padding-top: 0;
    }
  }
  .send-button {
    position: fixed;
    width: 100%;
    padding: 16px 32px;
    bottom: 0;
  }
}
</style>
