<template>
  <div class="edudash-score">
    <div class="score-title">
      {{ title }}
    </div>
    <star-rating
      :active-color="color"
      :star-size="48"
      :show-rating="false"
      :rating="score"
      class="star-rating"
      @rating-selected="emitScore"
    />
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';

export default {
  name: 'EdudashScore',
  components: {
    StarRating,
  },
  model: {
    prop: 'score',
    event: 'ratingSelected',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#29C68F',
    },
    score: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    emitScore(score) {
      this.$emit('ratingSelected', score);
    },
  },
};
</script>

<style lang="scss">
.edudash-score {
  .score-title {
    @include font-open-sans-regular;
    color: $text-color-primary;
    font-size: 12px;
    margin-bottom: 8px;
  }
  .star-rating {
    padding: 4px;
    margin-bottom: 8px;
  }
}
</style>
