<template>
  <div class="socioemotional-occurrences">
    <div
      v-for="(socioemotionalCategory, index) in socioemotionalCategories"
      :key="index"
      class="socioemotional-category"
    >
      <div class="socioemotional-category-title">
        {{ socioemotionalCategory.category_name }}
      </div>
      <div
        v-for="(criterium, criteriaIndex) in socioemotionalCategory.criteria"
        :key="criteriaIndex"
      >
        <edudash-score
          v-model="criterium.score"
          :title="criterium.occurrence_criterium.name"
        />
      </div>
    </div>
    <v-row>
      <v-col cols="6">
        <edudash-button
          :full-width="true"
          :disabled="disabled"
          @click="sendOccurrence"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EdudashScore from '@/components/forms/EdudashScore.vue';
import EdudashButton from '@/components/forms/EdudashButton.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Socioemotional',
  components: {
    EdudashScore,
    EdudashButton,
  },
  data() {
    return {
      socioemotionalCategories: [],
    };
  },
  computed: {
    ...mapGetters([
      'getCategories',
      'getOccurrenceSuccess',
      'getSchoolClass',
    ]),
    disabled() {
      return this.socioemotionalCategories
        .every((element) => element.criteria.every((criterium) => criterium.score < 1));
    },
  },
  watch: {
    getCategories(value) {
      this.socioemotionalCategories = value;
    },
    getOccurrenceSuccess(value) {
      if (value) {
        this.changeOccurrenceSuccess();
        this.$router.push({
          name: 'presenceList',
          params: {
            schoolClassId: this.getSchoolClass.id,
            studentId: this.$route.params.studentId,
          },
        });
      }
    },
  },
  mounted() {
    const params = {
      school_class_id: this.getSchoolClass.id,
      student_id: this.$route.params.studentId,
      day: this.$route.params.day,
      kind: 'socioemotional',
    };

    this.findCategories(params);
  },
  methods: {
    ...mapActions([
      'findCategories',
      'createSocioemotionalOccurrence',
      'changeOccurrenceSuccess',
    ]),
    sendOccurrence() {
      const occurrence = {
        description: this.getCategories[0].occurrence_description,
        school_class_id: this.getSchoolClass.id,
        student_id: this.$route.params.studentId,
        day: this.$route.params.day,
        socio_criteria: [],
        kind: 'socioemotional',
      };

      this.createSocioemotionalOccurrence({
        occurrence,
        socioemotional: this.socioemotionalCategories,
      });
    },
  },
};
</script>

<style lang="scss">
.socioemotional-occurrences {
  padding: 16px;
  .socioemotional-category {
    margin-bottom: 8px;
    .socioemotional-category-title {
      @include font-open-sans-bold;
      color: $text-color-category-title;
      font-size: $font-size-small;
      margin-bottom: 8px;
    }
  }
}
</style>
