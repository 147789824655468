<template>
  <v-list-item
    class="school-class-item"
    @click="goTo(schoolClass)"
  >
    <v-list-item-icon>
      <v-icon color="primary">
        $vuetify.icons.values.custom_group
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content class="school-class">
      <v-list-item-title class="class-name">
        {{ formatSchoolClassName(schoolClass) }}
      </v-list-item-title>
      <v-list-item-subtitle class="class-info">
        <v-row no-gutters>
          <v-col
            cols="4"
            class="info-col"
          >
            {{ schoolClass.shift }}
          </v-col>
          <v-col
            cols="8"
            class="info-col"
          >
            {{ $t('views.school_class.school_class_list.situation') }}
            <span
              :class="`presence-list-situation ${statusColorClass}`"
            >{{ status }}</span>
          </v-col>
        </v-row>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon>
      <v-icon color="secondary">
        keyboard_arrow_right
      </v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>
<script>

import { mapActions } from 'vuex';

export default {
  name: 'SchoolClassListItem',
  props: {
    schoolClass: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    status() {
      if (this.schoolClass.presence_list_status) {
        return this.$t('views.school_class.school_class_list.status_done');
      }
      return this.$t('views.school_class.school_class_list.status_pending');
    },
    statusColorClass() {
      return this.schoolClass.presence_list_status ? 'presence-list-done' : '';
    },
  },
  methods: {
    ...mapActions([
      'changeToolbarText',
      'findSchoolClass',
    ]),
    formatSchoolClassName(schoolClass) {
      return `${schoolClass.grade_number}º ${schoolClass.letter} - ${schoolClass.kind}`;
    },
    goTo(schoolClass) {
      this.findSchoolClass({ schoolClassId: schoolClass.id });
      this.$router.push({
        name: 'presenceList',
        params: {
          schoolClassId: schoolClass.id,
          schoolId: schoolClass.school_id,
        },
      });
      this.changeToolbarText(this.formatSchoolClassName(schoolClass));
    },
  },
};
</script>
<style lang="scss" scoped>
.school-class-item {
  padding: 0 0 18px;
  border-bottom: 1px solid $border-color;

  .v-list-item__icon {
    margin-right: 16px;
  }

  .school-class {
    padding: 0;
    .class-name {
      @include font-open-sans-semi-bold;
      font-size: $font-size-extra-small;
      color: $text-color-primary;
    }
    .class-info {
      @include font-open-sans-regular;
      font-size: $font-size-extra-small;
      color: $text-color-secondary;
      .info-col {
        padding: 4px 0 0;
        .presence-list-situation {
          @include font-open-sans-bold;
        }
        .presence-list-done {
          color: #29C68F;
        }
      }
    }
  }
}
</style>
