<template>
  <v-list-item
    class="student-list-item"
  >
    <v-list-item-action>
      <v-checkbox
        v-model="student.attendance"
        color="primary"
      />
    </v-list-item-action>
    <v-list-item-content class="school-class">
      <v-list-item-title class="class-name">
        {{ student.name }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn
        icon
        @click="addOccurrences"
      >
        <v-icon color="secondary">
          edit
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>
<script>

export default {
  name: 'PresenceListItem',
  props: {
    student: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    addOccurrences() {
      this.$emit('studentClick', this.student);
    },
  },
};
</script>
<style lang="scss" scoped>
.student-list-item {
  padding: 0 26px;
  &:nth-child(2n) {
    background-color: $list-background-color;
  }

  .school-class {
    .class-name {
      @include font-open-sans-semi-bold;
      font-size: $font-size-extra-small;
      color: $text-color-secondary;
    }
  }

  .v-list-item__action {
    margin-right: 8px;
  }
}
</style>
