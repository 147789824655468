<template>
  <div class="presence-list pa-4">
    <div class="school-select">
      <edudash-select
        v-model="selected"
        :items="getTeacherSchools"
        :placeholder="$t('views.school_class.school_class_list.school_select_placeholder')"
      />
    </div>
    <div class="school-class-list">
      <v-list flat>
        <v-list-item-group>
          <school-class-list-item
            v-for="(schoolClass, index) in schoolClasses"
            :key="index"
            :school-class="schoolClass"
            class="school-class-item"
          />
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import EdudashSelect from '@/components/forms/EdudashSelect.vue';
import SchoolClassListItem from '@/components/schoolClass/ListItem.vue';

export default {
  name: 'PresenceList',
  components: {
    EdudashSelect,
    SchoolClassListItem,
  },
  data() {
    return {
      selected: {},
      schoolClasses: this.selectedSchoolItems,
    };
  },
  computed: {
    ...mapGetters([
      'getTeacherSchools',
    ]),
    schoolItems() {
      return this.getTeacherSchools;
    },
    selectedSchoolItems() {
      return this.selected.school_classes;
    },
  },
  watch: {
    selected(value) {
      this.schoolClasses = value.school_classes.filter((schoolClass) => schoolClass.active);
    },
  },
  mounted() {
    this.findTeacherSchools();
    this.changeTab(this.$route.name);
  },
  methods: {
    ...mapActions([
      'findTeacherSchools',
      'fetchTeacher',
      'changeTab',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.presence-list {
  .school-class-list {
    .school-class-item {
      padding: 0 0 18px;
      border-bottom: 1px solid $border-color;
      .school-class {
        padding: 0;
        .class-name {
          @include font-open-sans-semi-bold;
          font-size: $font-size-extra-small;
          color: $text-color-primary;
        }
        .class-info {
          @include font-open-sans-regular;
          font-size: $font-size-extra-small;
          color: $text-color-secondary;
          .info-col {
            padding: 4px 0 0;
            .presence-list-situation {
              @include font-open-sans-bold;
            }
          }
        }
      }
    }
  }
}
</style>
